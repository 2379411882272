@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

* {
  padding: 0;
  margin: 0;
  max-height: inherit;
  box-sizing: border-box;
}

*::after,
*::before {
  box-sizing: border-box;
  max-height: inherit;
  padding: 0;
  margin: 0;
}

.loaderStyles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

.btnTop {
  margin-left: 0rem !important;
  margin-right: 1.5rem !important;
  margin-top: 1rem !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  width: 17em !important;
  // background-color: #eaeaea !important;
}

.btnTop.Mui-selected {
  background-color: #BC0000 !important;
}

.btnTopHeader.Mui-selected {
  background-color: transparent !important;
}

.btnInner {
  padding: 9px !important;
}

.btnInner span {
  padding: 6px;
  border-radius: 3px;
  color: black !important;
  font-weight: 600;
  width: 12.5em;
}

.btnInner.Mui-selected span {
  color: white !important;
  font-weight: 600;
}

.btnInner.Mui-selected .MuiTab-wrapper {
  background: rgb(157, 0, 0);
  background: linear-gradient(128deg,
      rgba(157, 0, 0, 1) 0%,
      rgba(83, 0, 0, 1) 100%);
}

.btnInner .MuiTab-wrapper {
  background-color: #d7d6d6 !important;
}

.btnModal {
  padding: 9px !important;
}

.btnModal.MuiTab-root {
  min-width: 100px !important;
  height: 2em;
}

.btnModal span {
  padding: 6px;
  border-radius: 3px;
  color: black !important;
  width: 10em;
  font-size: 11px;
}

.btnModal.Mui-selected span {
  color: white !important;
  font-weight: 600;
}

.btnModal.Mui-selected .MuiTab-wrapper {
  // background: rgb(157, 0, 0);
  // background: linear-gradient(
  //   128deg,
  //   rgba(157, 0, 0, 1) 0%,
  //   rgba(83, 0, 0, 1) 100%
  // );
  background: #BC0000 !important;
}

.btnModal .MuiTab-wrapper {
  background-color: #d7d6d6 !important;
}

.btnTop span {
  color: black !important;
  font-weight: 600;
}

.btnTop.Mui-selected span {
  color: white !important;
  font-weight: 600;
}

.btnTopHeader.Mui-selected span {
  color: #BC0000 !important;
  font-weight: 600;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.filterCheckbox {
  padding: 1px !important;
}

.filterCheckbox svg {
  fill: black !important;
}

.filterCheckbox.Mui-checked svg {
  fill: #bc0000 !important;
  margin-right: 0.4rem;
  // border: 2px solid #ff9700 !important;
}

.filterControl {
  width: 100%;
  padding-left: 0.5rem;
}

.filterControl .MuiFormControlLabel-label {
  font-family: "Open Sans";
  font-size: 14px;
}

.lobbyTableHead th {
  background: #f4f4f4;
  color: #000;
  font-size: 14px;
  padding: 0.46rem;
  text-align: center;
  position: sticky;
  // padding-left: 2px;
  top: 0;
  font-weight: 700;
  // border-radius: 4px;
  width: 70px;
}

.lobbyTableBody {
  // border-left: 2px solid #e4dede;
}

.lobbyTableBody td {
  color: #000000;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 50px;
  font-weight: 700;
  // padding-left: 2px;
}

// .lobbyTableBody tr:nth-child(even) {
//   background-color: #f9f9f9;
// }
.lobbyTableBody tr {
  background-color: #FBFBFB;
  // margin: 10px 0px;
}

.lobbyTableBodyGold tr {
  background-color: #f6f3ee;
  // margin: 10px 0px;
}

.tournamentsInnerTable {
  border: 1px solid #dee2e6 !important;
  text-align: center;
  font-size: 14px;
}

.tournamentsInnerTable th {
  font-weight: 600 !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.joinBtn {
  background: #009600;
  width: 8em;
  height: 2.5em;
  margin: 10px;
  border: 0;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.joinButtonFree {
  background-image: linear-gradient(#fffefe, #c4bcbc) !important;
  color: #000000 !important;
  width: 8em;
  height: 2.5em;
  margin: 10px;
  border: 0;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.joinBtnDisabled {
  background: #99999975;
  color: black;
  font-weight: 700 //pointer-events: none;
}

.joinBtnDisabledStartIn {
  background: #99999975;
  pointer-events: none;
  color: black;
  font-weight: 700
}

.innerModalTabPanel {
  background: #f4f4f4;
  height: 34.5em;
  overflow: scroll;
  scrollbar-width: none;
}

.modalDialogContainer {
  display: flex;
}

.modalStatusDialog {
  background: #009600;
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  flex: 0.25;
  margin: 0.15em;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  cursor: pointer;
  color: #fff;
}

.modalStatusDialog2 {
  background: rgb(128, 126, 126);
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  flex: 0.25;
  margin: 0.15em;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  cursor: none;
  pointer-events: none;
  color: #fff;
}

.modalStatusCancelled {
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  flex: 0.25;
  margin: 0.15em;
  font-size: 14px;
  font-weight: 300;
  position: relative;
}


.modalStatusDialog2 {
  background: rgb(128, 126, 126);
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  flex: 0.25;
  margin: 0.15em;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  cursor: none;
  pointer-events: none;
  color: #fff;
}
.modalStatusDialogDisable {
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  flex: 0.25;
  margin: 0.15em;
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

.modalStatusDialogDisable span {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.modalStatusDialog span {
  font-size: 16px;
  font-weight: 600;
}

.modalStatusCancelled span {
  font-size: 16px;
  font-weight: 600;
}

.startsDialog {
  // background: #780000;
  background: #BC0000 !important;
  color: white;
}

.closesDialog {
  background: #f4f4f4;
  color: black;
}

.joinDialogDisable {
  background: #009600;
  color: white;
}

.modalStatusIcon {
  position: absolute;
  background: #f4f4f4;
  height: 3em;
  width: 3em;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
  right: -1.5em;
  z-index: 1;
}

body {
  font-family: "Open Sans", sans-serif;
}

@import "bootstrap/dist/css/bootstrap.min.css";

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
  border-color: #ced4da;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid #7aeb7a;
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/////\\\

.ribbon-div {}

.ribbon {
  background: #9e813f;
  height: 20px;
  width: auto;
  display: inline-block;
  padding: 0 5px 0 10px;
  position: relative;
  color: #fff;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  margin-left: 15px;
}

.ribbon:before {
  content: '';
  border: 10px solid #9e813f;
  border-right-color: transparent;
  border-right-width: 2px;
  position: absolute;
  top: 0;
  right: -8px;
}

.ribbon:after {
  content: '';
  border: 10px solid transparent;
  border-right-color: #9e813f;
  border-right-width: 2px;
  position: absolute;
  top: 0;
  left: -8px;
}


//-------ribbon_mobile

.ribbon_mobile {
  background: #9e813f;
  height: 16px;
  /* Adjust the height as desired */
  width: auto;
  display: inline-block;
  padding: 0 3px 0 8px;
  /* Adjust padding as needed */
  margin: auto;
  position: relative;
  color: #fff;
  line-height: 16px;
  /* Adjust line-height as needed */
  font-size: 10px;
  /* Adjust font-size as needed */
  text-align: center;
  margin-left: 12px;
  /* Adjust margin as needed */
}

.ribbon_mobile:before {
  content: '';
  border: 8px solid #9e813f;
  /* Adjust border size as needed */
  border-right-color: transparent;
  /* Change border-left-color to border-right-color */
  border-right-width: 2px;
  /* Adjust border width as needed */
  position: absolute;
  top: 0;
  right: -6px;
  /* Change left to right for right-side view */
}

.ribbon_mobile:after {
  content: '';
  border: 8px solid transparent;
  border-right-color: #9e813f;
  /* Change border-left-color to border-right-color */
  border-right-width: 2px;
  /* Adjust border width as needed */
  position: absolute;
  top: 0;
  left: -6px;
  /* Change right to left for right-side view */
}





/********/




// html,
// body {
//   font-family: "Open Sans", sans-serif;
//   overflow-x: hidden;
// }



.footer {
  width: 100%;
  background-color: #760000;

}

.footer_inner {
  display: grid;
}

.footer_inside {
  margin: 3.125rem 0rem 1rem 0rem;
  /* grid-gap: 4.5%;
  grid-template-columns: 13% 28% 19% 23%; */
}

.firstRow {
  height: 100%;
  display: flex;
  grid-gap: 1rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: max-content;
}

.secondRow {
  height: 100%;
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.thirdRow {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.fourthRow {
  display: flex;
  grid-gap: 1rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer_logo {
  width: 6.5rem;
  height: 4.75rem;
}

.footer_para {
  font-size: 1rem;
  text-align: left;
  word-break: break-word;
  font-weight: 700;
  white-space: normal;
  margin-bottom: 16px;

}

.secondRow {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.quickLinks {
  font-size: 1rem;
  text-align: left;
  word-break: break-word;
  font-weight: 600;
  white-space: normal;
}

.thirdRow {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.fourthRow {
  display: flex;
  grid-gap: 1rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.footer_bottom {
  background-color: #600000;
}

ul.copyrights {
  padding: 10px;
  margin-bottom: -3px;
}

ul.copyrights li {
  display: inline-block;
  border-right: 1px solid #780000;
  padding: 10px;
  line-height: 0px;
}

ul.copyrights li:last-child {
  border-right: none;
}

ul.copyrights li a {
  font-size: 0.875rem;
  color: #fff;
}

.copyrightText {
  font-size: 0.875rem;
  color: #fff;

}

.allLinks img {
  width: 40px;
  height: 40px;
  margin-right: 4px;
}

.lastrowImages {
  height: 4rem;
  max-width: fit-content;
  align-self: flex-start;
}

ul.quickFirst {
  padding: 0;
  margin: 0;
}

ul.quickFirst li {
  list-style-type: none;
}

ul.quickFirst li a {
  color: #fff;
  border-bottom: 1px solid #780000;
  padding: 6px;
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid #871f1f;
  margin-left: 10px;
  word-wrap: normal;
  font-size: 0.875rem;
  white-space: normal;
  display: block;
}

ul.rummyLinks {
  margin-top: 15px;
  margin-left: -15px;
}




.contactButton {
  border-radius: 5px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.font14 {
  font-size: 14px;
}

.allLinks {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 95%;
}


@media (min-width: 1240px) {
  .footer_top {
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}



.footer_inside {
  display: flex;
}


.secondRow,
.thirdRow {
  padding-left: 25px;
  padding-top: 25px;
}

ul.quickFirst {
  width: 50%;
}

.mobile60 {
  width: 60%;
  float: left;
}

.mobile40 {
  width: 40%;
  float: left;
}


.firstRow {
  margin-top: -20px;
}



img {
  max-width: 100%;

}


@media (max-width: 992px) {
  .footer {
    margin-top: 5%;
  }

  .bgColor {
    width: 100%;
  }
}

.dynamicContent {
  p {
    margin-bottom: 0rem !important;
  }
}

/** Content Area Ends **/